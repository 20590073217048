import React from "react";

const Contact = () => {
    return (
        <>
            <img id={"score"} className="score" src={"images/scoreright2.gif"} alt={"music score for decoration"}/>
            <div id={"content"}>
            <h2><img className="headerGraphic" src={"images/headerGraphic.jpg"} height="30" width="4"
                     alt=""/>Contact <span>Information</span></h2>

            <p>Phone: (832) 270-6649<br/>
                Email: <a href="mailto:crickettsongs@hotmail.com">crickettsongs@hotmail.com</a></p>

            <h3>Lesson location</h3>
            <p>Lessons are available in the Danville, California area.</p>
            <p>Please feel free to call me between 9:00 AM and 9:00 PM daily</p>
            </div>
        </>
    )
};

export default Contact;
