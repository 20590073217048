import React from "react";

const About = () => {
    return (
        <>
            <img id={"score"} className="score" src={"images/scoreright2.gif"} alt={"music score for decoration"}/>
            <div id="content">

                <h2><img className="headerGraphic" src={"images/headerGraphic.jpg"} height="30" width="4"
                         alt=""/>About <span>Me</span></h2>

                <p>I began my violin studies at the age of five through the Suzuki method, and began playing viola
                    during college. Following high school, I received a full scholarship to Houston Baptist University,
                    where I studied with Rodica Weber-Gonzalez and earned a Bachelor of Music Degree with honors, and
                    later earned a Master of Liberal Arts degree. </p>

                <p>I have been teaching and performing for over thirteen years, and have registered approximately 100
                    hours of pedagogy and early childhood training with the Suzuki Association of the Americas. In my
                    Suzuki training, I have studied with Andrea Cannon, Peggy Ann Crow, Judy Offman, Cathy Lee, Beth
                    Titterington, Dorothy Jones and Sharon Jones. </p>
                <p>As a musician, I perform in a variety of styles for churches, various venues, weddings, and also for
                    studio recording sessions (see discography). </p>
                <p>~Christina</p>

            </div>
        </>
    )
};

export default About;
