import React from "react";

const PageNotFound = () => {
    return (
        <>
            <img id={"score"} className="score" src={"images/scoreright2.gif"} alt={"music score for decoration"}/>
            <div id={"content"}>
                <h2><img className="headerGraphic" src={"images/headerGraphic.jpg"} height="30" width="4"
                         alt=""/>404 <span>Page not Found</span></h2>

                <p>Oh boy, I think you got lost.</p>
            </div>
        </>
    )
};

export default PageNotFound;
