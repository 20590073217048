import React from 'react';

const Home = () => {
    return (
        <>
            <img id={"score"} className="score" src={"images/scoreright2.gif"} alt={"music score for decoration"}/>
            <div id={"content"}>
                <div id={"HomePage"}>
                    <img className="contentImg" id="contentImg1" src={"images/violinFholeGray2.jpg"} height="173" width="230" alt="Close up of a violin f hole"/>
                    <img className="contentImg" id="contentImg2" src={"images/violinOnTable.jpg"} height="165" width="220" alt="Violin sitting on table"/>
                    <img className="contentImg" id="contentImg3" src={"images/cickettcover.jpg"} height="200" width="200" alt="CrickettSongs by Christina David"/>
                </div>
            </div>
        </>
    );
};

export default Home;
