import React from 'react';
import { BrowserRouter as Router, Routes, Route}
  from 'react-router-dom';
import Home from './pages/home';
import About from './pages/about';
import Contact from './pages/contact';
import PageNotFound from './pages/404page';

function App() {
  return (
      <Router>
        <Routes>
            <Route exact path='/' element={<Home/>} />
            <Route path='/about' element={<About/>} />
            <Route path='/contact' element={<Contact/>} />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
  );
}

export default App;
